.page-header{
  .logo {
    padding: 30px;
    text-align: center;

    svg {
      width: 70px;
      height: 70px;
      fill: var(--accent-white);
    }
  }
}