#app-navbar {

  .navbar-brand {
    svg {
      height: 48px;
      fill: var(--accent-white);
    }
  }

  .nav-link {
    --bs-nav-link-font-size: 14px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 15px;
    border-bottom: 1px solid transparent;
    &.active {
      border-bottom: 1px solid #ffffff;
    }
  }

  .navbar-toggler {
    &:focus {
      box-shadow: none !important;
    }
  }

  @media (max-width: 992px) {
    .navbar-collapse {
      margin-top: 20px;
    }
  }

}