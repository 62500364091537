.what-we-do-section {
  position: relative;
  background-image: url("../../assets/images/what-we-do.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 171px;
  padding-bottom: 72px;

  .overlay {
    background-color: rgb(255, 255, 255, .5);
    inset: 0;
    position: absolute;
  }

  .slider-wrapper {
    position: relative;

    .swiper-slide {
      &:not(.swiper-slide-active) {
        visibility: hidden;
      }
    }

    .title {
      font-size: 28px;
      line-height: 37px;
      text-align: center;
      text-transform: uppercase;
      color: var(--accent-color);
    }

    .slider-item__inner {
      text-align: center;
      font-size: 5vw;
      line-height: 0.95;
      text-transform: uppercase;
      padding-top: 30px;
      padding-bottom: 155px;
      
      @media (max-width: 992px) {
        font-size: 9.5vw;
      }
      
    }
    .swiper-pagination-bullet {
      width: 20px;
      border-radius: 0;
      background: #1A1A1A;
    }


  }

}