.people-wrapper {

  .person-offcanvas {

    .offcanvas {
      --bs-offcanvas-width: 650px !important;
    }

    .person {
      .avatar {
        overflow: hidden;
        height: 320px;

        img {
          transition: 1.5s ease-in-out;
          width: 100%;
        }

        &:hover {
          img {
            scale: 1.15
          }
        }
      }
    }
  }
}