#projects {

  .arrows {
    svg {
      @media (max-width: 992px) {
        width: 35px!important;
        height: 35px!important;
      }
    }
  }

  .projects-slider {
    .swiper-slide {
      transition: .15s linear;
      @media (min-width: 992px) {
        width: 25%;

        &.swiper-slide-active {
          width: 50%;
        }
      }

      .slider-content {
        padding: 0 15px;
        .slide-img {
          height: 505px;
          width: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        .slide-video {
          height: 480px;
          background-color: #000;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .details {
          padding: 24px 0;
          @media (max-width: 992px) {
            padding: 24px 16px;
          }

        }

      }

    }
  }
}