@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
:root {
    --accent-color: #1A1A1A;
    --accent-white: #F4F4F4;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', sans-serif, 'PARKING';
    background: #ffffff;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #515151;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #848484;
}

.bg-black {
    background-color: #000 !important;
}

.color-black {
    color: #000;
}

.text-accent {
    color: var(--accent-color) !important;
}

.text-accent-white {
    color: var(--accent-white) !important;
}

.bg-accent-black {
    background-color: var(--accent-color) !important;
}

.bg-accent-white {
    background-color: var(--accent-color) !important;
}

.lh-0 {
    line-height: 0 !important;
}


.linear-bg-effect {
    padding: 10px 0;
    background: linear-gradient(to right, currentColor 0%, currentColor 100%) no-repeat left 85%;
    background-size: 0 1px;
    display: inline;
    line-height: 1;
    transition: .4s ease;
}

.linear-bg-effect:hover {
    background-size: 100% 2px;
}

.linear-bg-effect-wrapper:hover .linear-bg-effect {
    background-size: 100% 2px;
}

.font-parking {
    font-family: 'PARKING', 'Roboto';
}