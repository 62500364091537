#accordion-careers {

  .accordion-button{
    &:after {
      --bs-accordion-btn-icon-transform: none;
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6V18' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 12H18' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12H18' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    &:focus {
      box-shadow: none;
    }
  }

  .accordion-button{
    &:not(.collapsed) {
      color: unset;
      background-color: unset;
      box-shadow: none;
    }
  }
}