.hero-section {
  .hero-shot-wrapper {
    video {
      width: 100vw;
      @media (min-width: 1200px) {
        max-height: 734px;
      }
    }

    .video-overlay {
      height: 282px;
      background-image: linear-gradient(to top, #1A1A1A, rgba(0, 0, 0, 0));
    }
  }

  .hero-text {

    font-size: 3em;
    line-height: 1.2;
    max-width: 900px;

    @media (max-width: 992px) {
      font-size: 2em;
    }

    @media (max-width: 768px) {
      font-size: 1.5em;
    }

  }

  .logo-sm {
    padding: 30px;

    svg {
      width: 68px;
      fill: #fff;
    }
  }

  .people-places {
    font-size: 1.125em;
  }
}

