.logo-wrapper {
  .logo {
    svg {
      width: 60px;
    }
  }

  .wander {
    svg {
      width: 160px;
      fill: var(--accent-color);
    }
  }

  & > div {
    svg {
      transition: 0.4s ease;
    }

    &:hover {
      svg {
        fill: #888888;
      }
    }
  }
}

#go-top {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border: 2px solid #000;
  background: #fff;
  z-index: 99999;
  display: none;
  &.show {
    display: block;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #000;
  }

}

.social-links {
  li {
    &+li {
      margin-left: 14px;
    }
    a {
      display: block;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      font-size: 18px;
      border-radius: 50px;
      background-color: var(--accent-color);
      color: var(--accent-white);
      &:hover {
        background-color: #888888;
      }
    }
  }

}

.footer-part {
  h2 {
    font-size: 20px;
  }
  a {
    font-size: 14px;
  }
}